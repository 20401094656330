<template>
   <div class="modal-row2 modal-height-max" id="filter-reserva">
      <div class="modal-body">
         <div class="row gutters mt-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
               <div class="form-group">
                  <label>{{ t('NAV.ALLOTMENT') }}: </label>
                  <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento'}" :options="allotments"
                           v-model="allotmentid" @select="changeAllotment"/>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div class="form-group">
                  <label>{{ t('RESERVE.REALTOR') }}: </label>
                  <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Corretor'}" :options="realtors"
                           v-model="filter.realtorId" :disabled="allotmentid === null"/>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div class="form-group">
                  <label>{{ t('RESERVE.CLIENT') }}: </label>
                  <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Cliente'}" :options="clients"
                           v-model="filter.clientId" :disabled="allotmentid === null"/>
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
               <div class="form-group">
                  <label>{{ t('RESERVE.STATUS') }}: </label>
                  <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Status'}"
                           :options="[
                                       {id: 'Nenhum', text: 'Nenhum'},
                                       {id:'APROVADA', text: 'Aprovada'},
                                       {id:'EM AVALIAÇÃO', text: 'Em Avaliação'},
                                       {id: 'EXPIRADA', text: 'Expirada'},
                                       {id:'REJEITADA', text: 'Rejeitada'},
                                       {id: 'VENDA CANCELADA', text: 'Venda Cancelada'}
                           ]"
                           v-model="filter.status" :disabled="allotmentid === null"/>
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
               <div class="form-group">
                  <label>{{ t('PROPERTIES.BLOCK') }}: </label>
                  <Select2 :settings="{width: '100%'}" :options="blocks"
                           id="blockSelect"
                           placeholder="Selecione uma Quadra"
                           v-model="blockId" @select="searchBlock($event)" :disabled="allotmentid === null"/>
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
               <div class="form-group">
                  <label>{{ t('PROPERTIES.NAME') }}: </label>
                  <Select2 id="lots" :settings="{ width: '100%', placeholder: 'Selecione um lote'}" :options="lots"
                           v-model="filter.lots" @select="searchLots($event)"
                           :disabled="allotmentid === null"/>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer right">
         <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal" @click="$emit('close')">
            {{ t('ACTIONS.CLOSE') }}
         </button>
         <button type="button" class="btn btn-primary mr-1" @click="search()">{{ t('ACTIONS.FILTER') }}</button>
      </div>
   </div>
</template>


<script>

import {useI18n} from "vue-i18n";
import Reserve from "@/services/Reserves";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import {mapState} from "vuex";
import User from '@/services/user';
import Lots from '@/services/Lots';
import Proposal from '../../services/Proposal';

export default {
   name: 'FilterProposalComponent',
   mixins: [Validate],
   components: {
      Select2,
   },
   props: ['perPageIndex'],
   setup() {
      const {t} = useI18n();
      return {t}
   },

   data() {
      return {
         perPage: null,
         blocks: null,
         allotmentid: null,
         status: null,
         lots: [],
         blockId: null,
         itemsReserve: null,
         realtors: [{}],
         clients: [],
         filterBlock: null,
         filter: {
            lots: null,
            block: null,
            clientId: null,
            realtorId: null,
            status: null,
         },
      }
   },
   computed: {
      ...mapState({
         allotments: 'allotments',
      })
   },
   mounted() {
      this.allotmentid = localStorage.getItem('proposalAllotmentId');
      this.changeAllotment();
   },

   methods: {
      searchBlock({text, id}) {
         this.filter.block = id;
         this.blockId = id;
         this.filterblock = text == 'Nenhum' ? null : text;
      },
      searchLots({text}) {
         this.filter.lots = text == 'Nenhum' ? null : text;
      },
      async changeAllotment() {
         this.$store.commit('changeLoading', true);
         this.filter = {
            lots: null,
            block: null,
            clientId: null,
            realtorId: null,
            status: null,
         };
         this.clients = [];
         this.realtors = [];
         this.lots = [];
         this.blocks = null;
         this.startReserve(this.allotmentid);
         await this.indexRealtor(this.allotmentid);
         this.getLots(this.allotmentid);
         await this.indexBlocks(this.allotmentid);
         localStorage.removeItem('proposalAllotmentId');
         localStorage.setItem('proposalAllotmentId', this.allotmentid);
         this.$store.commit('changeLoading', false);
      },
      async indexBlocks(allotment_id) {
         await Lots.blocksList(allotment_id).then(async resp => {
            if (!resp.data?.validations) {
               let data = resp.data.map((i, k) => {
                  return{id: (k + 1), text: i.text};
               })
               this.blocks = [{id: 0, text: 'Nenhum'}, ...data];
            }
         }).catch(error => {
            this.errorMsg(error);
         });
      },

      async startReserve(allotment_id) {
         await this.indexReserve(allotment_id);
      },

      async indexReserve(allotment_id = this.allotmentid, page = 1) {
         this.$store.commit('changeLoading', true);
         await Reserve.index(this.filter, allotment_id, page).then(resp => {
            this.itemsReserve = resp.data;
            this.startAfterReseve(resp.data?.data);
         }).catch(error => {
            this.$store.commit('changeLoading', false);
            this.errorMsg(error);
         });
      },

      async startAfterReseve(reserves) {
         if (reserves) {
            await reserves.forEach((reserve,) => {
               this.indexClients(reserve);
            });
         }
      },

      indexClients(reseves) {
         reseves.reserves_clients.forEach(reserveClients => {
            if (reserveClients.clients) {
               if (!this.clients.find(client => client.id == reserveClients.clients?.id)) {
                  this.clients.push({id: reserveClients.clients?.id, text: reserveClients.clients?.name});
               }
            }
         });

         if (!this.clients.find(c => c.id == 0)) {
            this.clients.unshift({id: 0, text: this.t('GENERAL.NONE')});
         }
      },

      async getLots() {
         await Lots.getLotsByAllotment(this.allotmentid).then(resp => {
            this.lots = [{id: 0, text: 'Nenhum'}, ...resp.data];
         })
      },

      async indexRealtor(allotment_id) {
         await User.listRealtor(allotment_id).then(resp => {
            if (!resp.data.validations) {
               this.realtors = [];
               this.realtors = resp.data.filter(item => {
                  if (typeof item === 'object' && item !== null) {
                     return {
                        id: item.id,
                        text: item.text,
                     }
                  }
               });
               this.realtors.unshift({id: 0, text: this.t('GENERAL.NONE')});
            } else this.realtors = [{id: 0, text: 'Nenhum'}];
         }).catch(error => {
            this.errorMsg(error);
         });
      },

      search() {
         this.$store.commit('changeLoading', true);
         this.checkFilterNone();

         Proposal.indexProposal(this.filter,1, this.allotmentid, this.perPageIndex).then(resp => {
            this.$emit('search', resp, {...this.filter}, this.allotmentid);
            if (this.blockId != 0) {
               this.filter.block = this.blockId;
            }

            let div = document.createElement('div');
            div.classList.add('close');
            div.setAttribute('data-dismiss', 'modal');
            div.setAttribute('aria-label', 'Fechar');
            document.getElementById('filter-reserva').appendChild(div);
            div.click();
            div.remove();
            this.$store.commit('changeLoading', false);
         }).catch(error => {
            this.$store.commit('changeLoading', false);
            this.errorMsg(error);
         });
      },

      checkFilterNone() {
         this.filter.block = this.filter.block == 0 ? null : this.filterblock;
         this.filter.clientId = this.filter.clientId == 0 ? null : this.filter.clientId;
         this.filter.realtorId = this.filter.realtorId == 0 ? null : this.filter.realtorId;
         this.filter.status = this.filter.status === 'Nenhum' ? null : this.filter.status;
      },
   }
}
</script>